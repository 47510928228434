<template>
  <v-bottom-navigation
    :value="value"
    color="primary"
    grow
    dark
    shift
    fixed
  >
    <v-btn
      to="/treinos"
      x-small
    >
      <span>Treinos</span>
      <v-icon>fas fa-dumbbell</v-icon>
    </v-btn>

    <v-btn
      to="/avaliacoes"
      x-small
    >
      <span>Avaliações</span>
      <v-icon>fas fa-file-invoice</v-icon>
    </v-btn>

    <v-btn
      to="/"
      x-small
    >
      <span>Início</span>
      <v-icon>fas fa-book-open</v-icon>
    </v-btn>

    <v-btn
      to="/financeiro"
      x-small
    >
      <span>Financeiro</span>
      <v-icon>fas fa-dollar-sign</v-icon>
    </v-btn>

    <v-btn
      to="/menu"
      x-small
    >
      <span>Mais</span>
      <v-icon>fas fa-bars</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',
    data: () => ({
      value: 2,
    }),
    created () {
      if (this.$route.name === 'Perfil Pessoal') {
        this.value = 4
      }
    },
  }
</script>

<style>
.v-item-group.v-bottom-navigation .v-btn{
  min-width: 70px !important;
}
</style>
